
import { Form } from "vee-validate";
import { defineComponent, computed, watch, ref } from "vue";
import * as Validations from "yup";
import TextArea from "@/components/forms/TextArea.vue"
import Select from "@/components/forms/Select.vue"
import moduleForm from "@/store/modules/physicalInventory/modules/physical-count-form"
import { hideModalById } from "@/core/helpers/dom";
import { FormTypes } from "@/core/enums/form-types";
import NewPhysicalInventory from "@/models/physicalInventory/NewPhysicalInventory";

export default defineComponent({
    components: {
        Form
        , TextArea
        // , SelectFilterRemote
        , Select
    },
    setup() {
        const formData = ref();

        const validationSchema = computed(() => Validations.object().shape({
            warehouseId: Validations.string().default('').required()
            , observations: Validations.string().default('').optional().max(250)
        }));

        const save = (form: NewPhysicalInventory,  { resetForm }): void => {
            //save transfer
            if (formType.value == FormTypes.Update) { 
                moduleForm.UPDATE_FORM(form)
                    .then((isSuccess) => { 
                        if (isSuccess) { 
                            hideModalById('#new-physical-count');
                            resetForm();
                        } 
                    })
            } else {
                moduleForm.SAVE_FORM(form)
                    .then((isSuccess) => { 
                        if (isSuccess) {
                            hideModalById('#new-physical-count');
                            resetForm();
                        }
                    });
            }
        }
      

        const warehouseOptions = computed(() => moduleForm.selects.warehouseOptions);
        const formType = computed(() => moduleForm.formType);

        watch(() => moduleForm.getFormValue, (currentValue) => { 
            if (currentValue && currentValue.id != '') {
                formData.value.setValues({
                    ...(currentValue as NewPhysicalInventory)
                });
            } else {
                formData.value.resetForm();
            }
        })

        return {
            validationSchema
            , save
            , warehouseOptions
            , formData
            , formType
            , FormTypes
        }
    }
})
