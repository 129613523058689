export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "es": {
        "PLACE_HOLDERS": {
          "WAREHOUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecciona almacen"])}
        },
        "LABELS": {
          "ADVANCE_SERACH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Busqueda avanzada"])},
          "WAREHOUSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Almacen"])},
          "STATUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estado"])}
        },
        "BUTTONS": {
          "SEARCH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buscar"])},
          "CLEAN_FILTERS_FORM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limpiar"])}
        },
        "MESSAGES": {
          "NO_DATA_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No se encontraron registros"])}
        },
        "TOOLTIPS": {
          "DETAILS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detalles"])},
          "ENDCAPTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalizar Captura"])}
        }
      }
    }
  })
}
