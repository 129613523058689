
import { defineComponent, onMounted, computed, reactive, ref } from "vue";
import ModuleMaster from "@/components/c-module-master/module-master.vue";
import Datatable from "@/components/c-datatable/Datatable.vue";
import Datarow from "@/components/c-datatable/Datarow.vue";
import { formatToDatatable } from "@/common/formaterToDatatable";
import moduleTable from "@/store/modules/physicalInventory/modules/physical-count-table"
import moduleForm from "@/store/modules/physicalInventory/modules/physical-count-form"
import ModalNewPhysicalCount from "./components/ModalNewPhysicalCount.vue"
import CrudActionsV2 from "@/components/c-module-master/CrudActionsV2.vue";
import { FormTypes } from "@/core/enums/form-types";
import { translate } from "@/core/plugins/i18n";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { TransferStatus } from "@/core/enums/transfer-status"
import { useRouter } from "vue-router";
import { RouteNames } from "@/router/route-names";
import { toggleCollapse } from '@/core/helpers/dom';
import * as Validations from "yup";
import Select from '@/components/forms/Select.vue'
import { Form } from 'vee-validate'
import PhysicalInventoryRow from "@/models/physicalInventory/PhysicalInventoryRow";
import FiltersPhysicalInventory from "@/models/physicalInventory/filters/FiltersPhysicalInventory";
export default defineComponent({
    components: {
        ModuleMaster
        , Datatable
        , Datarow
        , ModalNewPhysicalCount
        , CrudActionsV2
        , Form
        , Select
    },
    setup() {
        const openModal = ref<HTMLButtonElement>();
        const router = useRouter();
        const configTable = reactive([
            { PropName: "id", HeadLabel: "Id", Type: "text", VisibleInGrid: false },
            { PropName: "folio", HeadLabel: "Folio", Type: "text", VisibleInGrid: true },
            { PropName: "warehouseName", HeadLabel: "Almacen", Type: "text", VisibleInGrid: true },
            { PropName: "createdAt", HeadLabel: "Fecha Creación", Type: "fullDate", VisibleInGrid: true },
            { PropName: "statusName", HeadLabel: "Estado", Type: "text", VisibleInGrid: true }
        ]);
        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });
        const labels = reactive({
            placeHolderToFieldSearch: "Buscar",
            titleNewCrudAction: "Nuevo",
        });

        const formFilters = ref();
        const btnFilters = ref();
        const validationSchemaFilters = Validations.object().shape({
            warehouseId: Validations.string().default('')
            , status: Validations.number().default(null).nullable(true).transform((_, val) => val === Number(val) ? val : null)
        })

        const idCollapse = ref('collapse-physical-count-filters');
        

        const optionsStatus = [
            { id: 0, name: "En Proceso" },
            { id: 1, name: "Finalizado" },
            { id: 2, name: "Aprobadas" }
        ]

        //computed
        const totalRecords = computed(() => moduleTable.totalRecords);
        const sizePage = computed(() => moduleTable.pagination.pageSize);
        const renderRows = computed(() => formatToDatatable(moduleTable.getRecordsTable, configTable));

        const handleChangePage = (page: number) => {
            moduleTable.CHANGE_PAGE({ currentPage: page });
        }
        const handleCrudAction = async (action: FormTypes = FormTypes.Create, id: string) => {
            if (typeof action === "string") {
                action = action == "N" ? FormTypes.Create
                    : (action == "M" ? FormTypes.Update
                        : action == "V" ? FormTypes.Read : FormTypes.Create);
            }

            moduleForm.SET_FORM_TYPE(action);
            if (action != FormTypes.Create) {
                moduleForm.SEARCH_INFO_TRANSFER(id);
            } else {
                moduleForm.RESET_VALUES_FORM();
                moduleForm.RESET_ID_SELECT();
            }
            openModal.value?.click();
        }

        const goToDetails = (row: PhysicalInventoryRow) => {
            moduleTable.SET_SELECT_ROW(row)
            router.push({
                name: RouteNames.PhysicalCountDetailsModule,
                params: {}
                , query: {
                    physicalId: row.id
                }
            });
        }

        onMounted(() => {
            moduleTable.getComboWarehouse("");
            moduleForm.getComboWarehouse("");
            moduleTable.SEARCH_TABLE_DATA();
            setCurrentPageBreadcrumbs(translate('BREADCRUMB.PHYSICAL_COUNT'), [translate('BREADCRUMB.MODULES'), translate('BREADCRUMB.PHYSICAL_INVENTORY')]);
        });

        const search = (filters: Partial<FiltersPhysicalInventory>) => {
            moduleTable.UPDATE_FILTERS(filters)
            toggleCollapse("#" + idCollapse.value);
        }

        const cleanFilters = () => {
            formFilters.value?.resetForm();
            moduleTable.RESET_FILTERS();
            btnFilters.value?.click();
        }

        const warehouseOptions = computed(() => moduleTable.selects.warehouseOptions);
        return {
            headers
            , totalRecords
            , sizePage
            , renderRows
            , labels
            , idCollapse
            , formFilters
            , btnFilters
            , optionsStatus
            , validationSchemaFilters
            , openModal
            , handleChangePage
            , handleCrudAction
            , TransferStatus
            , goToDetails
            , search
            , warehouseOptions
            , cleanFilters
        }
    }
})
