import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "physical-count-view" }
const _hoisted_2 = { id: "physical-count-view__advance-search" }
const _hoisted_3 = { class: "card mb-5 mb-xl-10" }
const _hoisted_4 = ["data-bs-target", "aria-controls"]
const _hoisted_5 = { class: "card-title m-0" }
const _hoisted_6 = { class: "svg-icon svg-icon-2 svg-icon-gray-500 me-1" }
const _hoisted_7 = { class: "fw-bolder ml-2" }
const _hoisted_8 = ["id"]
const _hoisted_9 = { class: "row g-9 mb-8" }
const _hoisted_10 = { class: "col-md-6 col-xs-12 fv-row" }
const _hoisted_11 = { class: "col-md-6 col-xs-12 fv-row" }
const _hoisted_12 = { class: "d-flex justify-content-end" }
const _hoisted_13 = {
  type: "submit",
  class: "btn btn-success",
  ref: "btnFilters"
}
const _hoisted_14 = { class: "svg-icon svg-icon-2 svg-icon-gray-500 me-1" }
const _hoisted_15 = { class: "physical-count-view__physical-count-table" }
const _hoisted_16 = ["title", "onClick"]
const _hoisted_17 = { class: "svg-icon svg-icon-3" }
const _hoisted_18 = {
  hidden: "",
  ref: "openModal",
  class: "btn btn-primary er fs-6",
  "data-bs-toggle": "modal",
  "data-bs-target": "#new-physical-count"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Select = _resolveComponent("Select")!
  const _component_Form = _resolveComponent("Form")!
  const _component_CrudActionsV2 = _resolveComponent("CrudActionsV2")!
  const _component_Datarow = _resolveComponent("Datarow")!
  const _component_Datatable = _resolveComponent("Datatable")!
  const _component_ModuleMaster = _resolveComponent("ModuleMaster")!
  const _component_ModalNewPhysicalCount = _resolveComponent("ModalNewPhysicalCount")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "card-header border-0 cursor-pointer",
          role: "button",
          "data-bs-toggle": "collapse",
          "data-bs-target": `#${_ctx.idCollapse}`,
          "aria-expanded": "true",
          "aria-controls": _ctx.idCollapse
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, [
              _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen021.svg" })
            ]),
            _createElementVNode("h3", _hoisted_7, _toDisplayString(_ctx.$t('LABELS.ADVANCE_SERACH')), 1)
          ])
        ], 8, _hoisted_4),
        _createElementVNode("div", {
          class: "collapse hide m-10",
          id: _ctx.idCollapse
        }, [
          _createVNode(_component_Form, {
            as: "el-form",
            onSubmit: _ctx.search,
            "validation-schema": _ctx.validationSchemaFilters,
            ref: "formFilters"
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('LABELS.WAREHOUSE')), 1),
                  _createVNode(_component_Select, {
                    name: 'warehouseId',
                    options: _ctx.warehouseOptions,
                    placeholder: 'Filtra por almacen'
                  }, null, 8, ["options"])
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('LABELS.STATUS')), 1),
                  _createVNode(_component_Select, {
                    name: 'status',
                    options: _ctx.optionsStatus,
                    placeholder: 'Filtra por estado'
                  }, null, 8, ["options"])
                ])
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("button", {
                  type: "reset",
                  id: "kt_modal_new_target_cancel",
                  class: "btn btn-sm btn-white me-3 float-start",
                  "data-kt-menu-dismiss": "true",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.cleanFilters && _ctx.cleanFilters(...args)))
                }, _toDisplayString(_ctx.$t("BUTTONS.CLEAN_FILTERS_FORM")), 1),
                _createElementVNode("button", _hoisted_13, [
                  _createElementVNode("span", _hoisted_14, [
                    _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen021.svg" })
                  ]),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('BUTTONS.SEARCH')), 1)
                ], 512)
              ])
            ]),
            _: 1
          }, 8, ["onSubmit", "validation-schema"])
        ], 8, _hoisted_8)
      ])
    ]),
    _createElementVNode("section", _hoisted_15, [
      _createVNode(_component_ModuleMaster, {
        labels: _ctx.labels,
        requirePaginator: true,
        numItemsPaginator: _ctx.totalRecords,
        itemsPerPage: _ctx.sizePage,
        requireSearchField: false,
        requireAddButton: true,
        fnCallbackChangePage: _ctx.handleChangePage,
        fnCallbackCrudAction: _ctx.handleCrudAction
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Datatable, {
            headers: _ctx.headers,
            requireActions: true,
            requireStatusCol: false
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.renderRows, (row) => {
                return (_openBlock(), _createBlock(_component_Datarow, {
                  key: row.ID,
                  dataRow: row,
                  showCrudActions: true
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_CrudActionsV2, {
                      onActionCrud: ($event: any) => (_ctx.handleCrudAction(...arguments, row.ID))
                    }, null, 8, ["onActionCrud"]),
                    _createElementVNode("div", {
                      class: "btn btn-icon btn-sm btn-active-icon-primary",
                      "data-bs-toggle": "tooltip",
                      "data-bs-placement": "bottom",
                      title: _ctx.$t(`TOOLTIPS.DETAILS`),
                      onClick: ($event: any) => (_ctx.goToDetails(row.originalProps))
                    }, [
                      _createElementVNode("span", _hoisted_17, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotune/abstract/abs015.svg" })
                      ])
                    ], 8, _hoisted_16)
                  ]),
                  _: 2
                }, 1032, ["dataRow"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["headers"])
        ]),
        _: 1
      }, 8, ["labels", "numItemsPaginator", "itemsPerPage", "fnCallbackChangePage", "fnCallbackCrudAction"]),
      _createElementVNode("button", _hoisted_18, null, 512),
      _createVNode(_component_ModalNewPhysicalCount)
    ])
  ]))
}