import Modules from "@/store/enums/Modules";
import { Action, Module, Mutation, MutationAction, VuexModule, getModule } from "vuex-module-decorators";
import store from "@/store";
import { ActionsForm } from "@/store/commons/ActionsForm";
import { MutationsForm } from "@/store/commons/MutationsForm";
import { realoadTable } from "@/store/functions/GenericTable";
import OptionSelect from "@/models/shared/OptionSelect";
import WarehouseComboService from "@/core/services/werehouse/WarehouseComboService";
import { FormTypes } from "@/core/enums/form-types";
import NewPhysicalCountState from "../../model/NewPhysicalCountState";
import NewPhysicalInventory from "@/models/physicalInventory/NewPhysicalInventory";
import PhysicalInventoryService from "@/core/services/physicalInventory/PhysicalInventoryService";

enum Mutations { 
    SET_VALUE_LOADING = "SET_VALUE_LOADING"
    , SET_INFO = "SET_INFO"
}
enum Actions { 
    SEARCH_INFO_TRANSFER = 'SEARCH_INFO_TRANSFER'
}
const defaultValue = {
    id: ""
    , observations: ''
    , warehouseId: ''
} as NewPhysicalInventory;

@Module({dynamic: true, store, namespaced: true, name: Modules.NewPhysicalCountModule})
class NewPhysicalCountModule extends VuexModule implements NewPhysicalCountState {
    idSelected = '';
    formValue = defaultValue;
    loading = false;
    selects = {
        warehouseOptions: [] as OptionSelect[] 
    };
    formType = FormTypes.Create;

    get getFormValue(): NewPhysicalInventory {
        return this.formValue;
    }
    /**
     * Indica si existe un identificador
     */
    get IsUpdateForm() : boolean {
        return this.idSelected != '';
    }


    @Mutation
    [Mutations.SET_INFO](value: NewPhysicalInventory){
        this.formValue = value;
        this.idSelected = value.id;

    }
    @Mutation
    [MutationsForm.RESET_ID_SELECT](){
        this.idSelected = '';
    }
    @Mutation
    [Mutations.SET_VALUE_LOADING](value: boolean){
        this.loading = value;
    }
    @Mutation
    [MutationsForm.SET_FORM_TYPE](newFormType: FormTypes){
        this.formType = newFormType;
    }
    
    @Mutation
    [MutationsForm.RESET_VALUES_FORM](){
        this.formValue = defaultValue;
        this.idSelected = '';
    }
    
    @Action({ commit: Mutations.SET_INFO })
    async [Actions.SEARCH_INFO_TRANSFER](id: string){
        this.context.commit(Mutations.SET_VALUE_LOADING, true);
        return (await service.getInfo(id)
            .finally( () => this.context.commit(Mutations.SET_VALUE_LOADING, false))).data ?? []
    }
      @Action
    async [ActionsForm.SAVE_FORM](form: NewPhysicalInventory){
        this.context.commit(MutationsForm.SET_LOADINGS, true);
        return service.save(form)
            .then((resp) => {
                let isSuccess = false;
                if(resp.data){
                    realoadTable(this.context, Modules.TablePhysicalCountModule)
                    this.context.commit(MutationsForm.RESET_VALUES_FORM)
                    isSuccess = true;
                } 
                return isSuccess;
            })
            .finally(() => {
                this.context.commit(MutationsForm.SET_LOADINGS, false)
            })
    }
    @Action
    async [ActionsForm.UPDATE_FORM](newData: NewPhysicalInventory){
        this.context.commit(MutationsForm.SET_LOADINGS, true);
        return service.update(this.idSelected, newData)
            .then((resp) => {
                let isSuccess = false;
                if(resp.data){
                    realoadTable(this.context, Modules.TablePhysicalCountModule)
                    this.context.commit(MutationsForm.RESET_VALUES_FORM)
                    isSuccess = true;
                } 
                return isSuccess;
            })
            .finally( () => {
                this.context.commit(MutationsForm.SET_LOADINGS, false)
            });
    }
   @MutationAction
    async getComboWarehouse(filterName: string) {
        //this.context.commit(MutationsOwner.SET_LOADINGS, { warehouseOptions: true });
        const states = await warehouseService.searchCombo(filterName)
            /*.finally(() => {
                this.context.commit(MutationsOwner.SET_LOADINGS, { warehouseOptions: false });
            });*/
        const selects = { ...this.selects }
        return { selects: { ...selects, warehouseOptions: states.data ?? [] } }
    }
}

const service =  new PhysicalInventoryService();
const warehouseService = new WarehouseComboService();

export default getModule(NewPhysicalCountModule);
