
import { defineComponent } from "vue"
import DynamicContentModal from "@/components/modals/general/DynamicContentModal.vue"
import NewPhysicalCountForm from "./NewPhysicalCountForm.vue"
import { hideModalById } from "@/core/helpers/dom"

export default defineComponent({
    components: {
        DynamicContentModal
        , NewPhysicalCountForm
    }
    , setup() {
        const closeModal = () => {
            hideModalById("#new-physical-count");
        };
        return {
            closeModal
        }
    }
})
